import { useEffect, useRef } from 'react'

interface Props {
  callback?: () => void
  delay: number | null
}

const useInterval = (callback: Props['callback'], delay: Props['delay']) => {
  const savedCallback = useRef<any>({})

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }

    return () => { }
  }, [delay])
}

export default useInterval
